import MessengerChat from "../components/messenger"
import Header from "../components/common/header"
import HeroSection from "../components/homepage/HeroSection"
import React from "react"
import { Anchor, Button, Image, Text } from "atomize"
import Layout from "../components/layout"
import Illustration from "../images/clip-its-time-for-present.png"
import firebase from "gatsby-plugin-firebase"
const Thanks = () => {
  const layout = "layout-2"
  return (
    <Layout>
      <MessengerChat />

      <React.Fragment>
        <Header layout={layout} />
        <div style={{ height: "64px" }} />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image src={Illustration} w="30%" />
          <Text
            tag="h1"
            textWeight="700"
            textAlign="center"
            textSize="display3"
            fontFamily="secondary"
            textColor={"black"}
            m={{ t: "3rem", b: "3rem" }}
            style={{ fontSize: "28px" }}
          >
            Chúng tôi sẽ liên lạc với bạn trong thời gian sớm nhất.
            <br />
            <span style={{ fontSize: "20px" }}>
              Bạn có thể trải nghiệm App miễn phí ngay bây giờ
            </span>
          </Text>

          <Anchor
            href="https://growth.ucall.vn/"
            onClick={() => {
              firebase
                .analytics()
                .logEvent("tap_on_trai_nghiem_from_thanks_page")
            }}
            w="30%"
            h="100%"
            target="_blank"
          >
            <Button
              bg="success700"
              hoverBg="success800"
              textColor="medium"
              w="100%"
              rounded="lg"
              m={{ t: "16px", b: "16px" }}
              style={{
                letterSpacing: "-0.5px",
                padding: "16px",
                color: "white",
              }}
              height={"50px"}
            >
              Trải Nghiệm Ngay
            </Button>
            <div style={{ height: "64px" }} />
          </Anchor>
        </div>
      </React.Fragment>
    </Layout>
  )
}
export default Thanks
